import { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Tabs, TypographyButton } from '@components';
import { useLoadYotpo } from '@services/yotpo';
import { capitalizeEachWord } from '@utils/strings';
import { useCustomer } from '@services/shopify';
import { useTranslation } from '@utils/index';
import styles from './AccountTabs.module.scss';

type AccountTabsProps = {
	defaultValue: string;
};

const getTranslatedText = translator => {
	return {
		myTabName: (tabName: string) => {
			return translator('my-tab-name', { tabName });
		},
	};
};

const AccountTab = ({ activeTab, tabName }) => {
	const ref = useRef(null);
	const router = useRouter();
	const { translator } = useTranslation();
	const translations = getTranslatedText(translator);

	const scrollIntoView = () => ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

	useEffect(() => {
		if (ref?.current && activeTab === tabName) {
			scrollIntoView();
		}
	}, [ref?.current, activeTab]);

	return (
		<Tabs.Trigger
			asChild
			ref={ref}
			value={tabName}
			onClick={() => {
				scrollIntoView();
				router.push(tabName !== 'rewards' ? `/account/${tabName}` : `/${tabName}`, undefined, { shallow: true });
			}}
		>
			<TypographyButton small>{capitalizeEachWord(translations.myTabName(tabName))}</TypographyButton>
		</Tabs.Trigger>
	);
};

const AccountTabs = ({ defaultValue = 'collection' }: AccountTabsProps) => {
	useLoadYotpo();
	const { locale } = useRouter();
	const { data: customer } = useCustomer();

	const showSubscription = locale === 'en-US';
	const showRewards = locale === 'en-US' || locale === 'en-CA';
	let tabs = ['favorites', 'collection', 'orders', 'rewards', 'subscriptions'];

	tabs = tabs.filter(
		tab => (tab !== 'rewards' || showRewards) && (tab !== 'subscriptions' || (customer?.subscriberId && showSubscription))
	);

	return (
		<Tabs value={defaultValue} activationMode='manual' className={styles.list}>
			<Tabs.List>
				{tabs.map(tabName => (
					<AccountTab key={tabName} activeTab={defaultValue} tabName={tabName} />
				))}
			</Tabs.List>
		</Tabs>
	);
};

export default AccountTabs;
